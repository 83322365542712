.slides {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
}

@media screen and (max-width: 960px) {
  .slides {
    height: 75vh;
  }
}

@media screen and (max-width: 768px) {
  .slides {
    height: 65vh;
  }
}

@media screen and (max-width: 580px) {
  .slides {
    height: 45vh;
  }
}
@media screen and (max-width: 400px) {
  .slides {
    height: 30vh;
  }
}

@media screen and (max-width: 350px) {
  .slides {
    height: 25vh;
  }
}
