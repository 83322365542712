.navbar {
  /* background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(127, 127, 127) 100%); */
  background: #000000;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  max-width: 100%;
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: row;
}

.navbar-item {
  display: flex;
}

.navbar-items {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.padding-left {
  padding-left: 10%;
}

.padding-right {
  padding-right: 10%;
}

@media screen and (max-width: 760) {
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .navbar {
    height: 40px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 5px;
    left: 0;
    transform: translate(25%, 50%);
  }

  .navbar-logo > h4 {
    font-size: 1.2rem;
  }

  .fa-bars {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 1.2rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #c3c3c3;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 1rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #c3c3c3;
    padding: 14px 20px;
    border: 1px solid #c3c3c3;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #c3c3c3;
    color: #242424;
    transition: 250ms;
  }
}
